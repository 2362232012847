import React, { Component } from 'react'
import {
    validatePassword,
} from "../../helpers/utility";
import { Form, Input, Modal } from 'antd';
import { getIsSuperAdmin } from "../../helpers/roleUtility";
import API from '../../helpers/API';
import createNotification from "../../components/notification";

class updatePwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            passwordLoader: false,
            loading: false,
            newPass: undefined,
            confirmPass: undefined,
            avatarList: false,
            clickNew: false,
            clickConfirm: false,
            openModal: false,
        };
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleNewEyeClick = (type) => {
        this.setState({ clickNew: !this.state.clickNew });
    };
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("newPass")) {
            callback("Password mismatch!");
        } else {
            callback();
        }
    };
    handleConfirmEyeClick = (type) => {
        this.setState({ clickConfirm: !this.state.clickConfirm });
    };
    handleSubmit = (e) => {
        const { profile } = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async(err, values) => {
          if (err || getIsSuperAdmin(this.props.profile)) {
          } else {
            this.setState({ passwordLoader: true });
            const payload = {
              password: this.state.newPass,
            };
            const res = await API.profile.updateUser(this.props.profile.id, payload) // Update API call
            console.log(res)
            createNotification('success', res?.message || 'successfully Applied');
            this.props.onUpdate();
            this.props.onCancel();
            // this.props.changePassword(payload, profile._id, (val) => {
              this.setState({ passwordLoader: false });
              this.props.form.resetFields();
            // });
          }
        });
      };
    render() {
        const { getFieldDecorator } = this.props.form;
        const FormItem = Form.Item;

        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                closeButton
                title={"Update Password"}
                // footer={null}
                onOk={this.handleSubmit}
                // destroyOnClose
                maskClosable={false}
                okButtonProps={{ loading: this.state.passwordLoader }}
                closable
                zIndex={10002}
            >
                <div className="isoSignInForm" style={{ position: "relative" }}>
                    <Form>
                        <FormItem label="New Password">
                            {getFieldDecorator("newPass", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please enter new password",
                                    },
                                    {
                                        validator: validatePassword,
                                    },
                                ],
                                validateTrigger: "onBlur",
                            })(
                                <Input
                                    size="large"
                                    id="newPass"
                                    type={this.state.clickNew ? "text" : "password"}
                                    placeholder="New Password"
                                    name="newPass"
                                    onChange={(e) => this.handleChange(e)}
                                />
                            )}
                            <img
                                onClick={this.handleNewEyeClick}
                                src={
                                    this.state.clickNew
                                        ? require("../../image/EyeOpen.png").default
                                        : require("../../image/EyeClosed.png").default
                                }
                                style={{
                                    position: "absolute",
                                    width: "15px",
                                    height: "15px",
                                    right: "1rem",
                                    top: "0rem",
                                }}
                            />
                        </FormItem>
                        <FormItem label="Confirm Password">
                            {getFieldDecorator("confirmPass", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please re enter new password",
                                    },
                                    {
                                        validator: this.compareToFirstPassword,
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    type={this.state.clickConfirm ? "text" : "password"}
                                    id="confirmPass"
                                    placeholder="Confirm Password"
                                    name="confirmPass"
                                    onChange={(e) => this.handleChange(e)}
                                />
                            )}
                            <img
                                onClick={this.handleConfirmEyeClick}
                                src={
                                    this.state.clickConfirm
                                        ? require("../../image/EyeOpen.png").default
                                        : require("../../image/EyeClosed.png").default
                                }
                                style={{
                                    position: "absolute",
                                    width: "15px",
                                    height: "15px",
                                    right: "1rem",
                                    top: "0rem",
                                }}
                            />
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}

export default ((updatePwd = Form.create()(updatePwd)));
