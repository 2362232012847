const colors = [
  "#FF5733", // Vibrant Red
  "#33B5FF", // Bright Blue
  "#28A745", // Green
  "#FFC107", // Yellow
  "#8E44AD", // Purple
  "#FF69B4", // Pink
  "#34495E", // Dark Grey-Blue
  "#E67E22", // Orange
  "#1ABC9C", // Teal
  "#C0392B", // Crimson
  "#2980B9", // Deep Blue
  "#F39C12", // Amber
  "#7D3C98", // Plum
  "#16A085", // Dark Teal
  "#D35400", // Burnt Orange
];


const graphConfigurations = [
  {
    id: 1,
    // series: [{ name: "Revenue", data: [50, 80, 65, 90, 100, 120,50, 80, 65, 90,40 , 40] }],
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      // colors:['#063274','#008FFB'],
      dataLabels: { enabled: false },
      title: { text: "Learning Hours by Month", align: "center" },
      xaxis: { categories: [] },
      // xaxis: { categories: ["Jan-1" , 'feb-1' , 'mar-1','Apr-1','may-1', 'Jun-1','July-1', 'Aug-1', 'Sep-1' , 'Oct-1' , 'Nov-1' , 'Dec-1'] },
    },
  },

  {
    id: 2,
    series: [],
    // series: [{ name: "Time", data: [50, 80, 65, 90, 100, 200,50, 80, 65, 90, 100, 200,50, 80, 65, 90, 100, 200,50, 80, 65, 90, 100, 200,50, 80, 65, 90, 100, 200] }],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      dataLabels: { enabled: false },
      title: {
        text: "Daily Study Time (mins) Past 30 Days",
        align: "center",
      },
      xaxis: { categories: [] },
      // xaxis: { categories: ["01-nov", "02-nov", "03-nov", "04-nov","05-nov","06-nov","07-nov","08-nov","09-nov","10-nov","11-nov","12-nov","13-nov","14-nov","15-nov","01-nov", "02-nov", "03-nov", "04-nov","05-nov","06-nov","07-nov","08-nov","09-nov","10-nov","11-nov","12-nov","13-nov","14-nov","15-nov",] },
    },
  },
  {
    id: 3,
    series: [],
    // series: [ {
    //   name: "Active Days",
    //   data: [15, 25, 20, 30, 28, 20, 31, 22, 22, 25, 25, 29], // The original data
    // },
    // {
    //   name: "Remaining Days",
    //   data: [31 - 15, 31 - 25, 31 - 20, 31 - 30, 31 - 28, 31 - 20, 31 - 31, 31 - 22, 31 - 22, 31 - 25, 31 - 25, 31 - 29], // Remaining days
    // }],
    options: {
      chart: { type: "bar", stacked: true, toolbar: { show: false } },
      dataLabels: { enabled: false },
      colors: ["#063274", "#008FFB"],
      title: { text: "Active Days by Month", align: "center" },
      // xaxis: { categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July","Aug" , "Sep","Oct" , "Nov" , "Dec"] },
      xaxis: { categories: [] },
    },
  },
  {
    id: 4,
    series: [],
    // series: [{ name: "time", data: [50, 80, 65, 90, 100, 22 , 23] }],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      dataLabels: { enabled: false },
      title: { text: "Weekly Study Profile", align: "center" },
      // xaxis: { categories: ["Mon" , 'Tue' , 'Wed','Thu','Fri', 'Sat','Sun'] },
      xaxis: { categories: [] },
    },
  },
  {
    id: 5,
    // series: [{ name: "Time", data: [50, 80, 65, 90, 100, 200,50, 80, 65, 90, 100, 200,50, 80, 65, 90, 100, 200,50, 80, 65, 90, 100, 200] }],
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      title: { text: "Study Time Profile", align: "center" },
      dataLabels: { enabled: false },
      xaxis: { categories: [] },
      // xaxis: { categories: ["1", "2", "3", "4","5","6","7","8","9","10","11","12","13","14","15","16", "17", "18", "19","20","21","22","23","24",] },
    },
  },
  {
    id: 6,
    // series: [70,30],
    series: [],
    options: {
      chart: { type: "pie", toolbar: { show: false } },
      dataLabels: { enabled: true },
      // labels: ["Focussed", "Distacted"],
      labels: [],
      title: { text: "Distraction Factor", align: "center" },
      legend: {
        show: true,
        position: "bottom",
      },
    },
  },

  {
    id: 7,
    isStatic: false, // Static graph
    // series: [35, 20, 18, 10, 5, 7],
    series: [],
    options: {
      chart: { type: "donut", toolbar: { show: false } },
      // dataLabels: { enabled: true ,align:"below" },
      // labels: [
      //   "Maths",
      //   "Physical science",
      //   "Life science",
      //   "Geography",
      //   "LO",
      //   "English FAL",
      // ],
      labels: [],
      title: { text: "Subject Study Ratio", align: "center" },
      dataLabels: {
        enabled: true,
        // formatter: function (value, opts) {
        //   const label = opts.w.config.labels[opts.seriesIndex];
        //   const maxLength = 15;
        //   return label.length > maxLength ? label.substring(0, maxLength) + "..." : label;
        // },
      },
      // tooltip: {
      //   enabled: true,
      //   y: {
      //     formatter: function (value, opts) {
      //       return opts.w.config.labels[opts.seriesIndex];
      //     },
      //   },
      // },
      legend: {
        show: true,
        position: "bottom",
      },
    },
  },
  {
    id: 8,
    isStatic: false, // Static graph
    // series: [
    //   {
    //     name: "Recruitment",
    //     data: [5, 10, 6, 10, 21, 22, 30],
    //   },
    // ],
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: true, barHeight: "80%", isFunnel: true },
      },
      title: { text: "Time Per Classroom Past 30 Days", align: "center" },
      // xaxis: {
      //   categories: [
      //     "Maths",
      //     "Physical science",
      //     "Life science",
      //     "Geography",
      //     "LO",
      //     "English FAL",
      //   ],
      // },
      xaxis: { categories: [] },
    },
    dataLabels: { enabled: false },
  },
  {
    id: 9,
    isStatic: false, // Static graph
    series: [
      // {
      //   name: "Recruitment",
      //   data: [25, 50, 38, 40, 71, 86, 80],
      // },
    ],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: true, barHeight: "80%", isFunnel: true },
      },
      title: { text: "Mastery Per Class - TRADITIONAL", align: "center" },
      // xaxis: {
      //   categories: [
      //     "Maths",
      //     "Physical science",
      //     "Life science",
      //     "Geography",
      //     "LO",
      //     "English FAL",
      //   ],
      // },
      xaxis: { categories: [] },
    },
    dataLabels: { enabled: false },
  },
  // Line Chart
  {
    id: 10,
    isStatic: false, // Static graph
    // series: [
    //   { name: "Maths", data: [10, 20, 15, 25, 30, 45] },
    //   { name: "Physical Science", data: [10, 40, 45, 25, 20, 45] },
    //   { name: "Geography", data: [15, 36, 88, 75, 20, 45] },
    //   { name: "Life Science", data: [2, 10, 15, 25, 30, 25] },
    //   { name: "LO", data: [9, 20, 25, 55, 60, 55] },
    // ],
    series: [],
    options: {
      chart: { type: "line", toolbar: { show: false } },
      title: {
        text: "Mastery Progress Over Time - TRADITIONAL",
        align: "center",
      },
      // xaxis: { categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"] },
      xaxis: { categories: [] },
      // colors: [
      //   "#FF5733", "#33B5FF", "#28A745", "#FFC107", "#8E44AD",
      //   "#FF69B4", "#34495E", "#E67E22", "#1ABC9C", "#C0392B"
      // ],
      colors: colors
    },
    dataLabels: { enabled: false },
  },
  // {
  //   isStatic: true,
  //   series: [
  //     { name: "IIOT", data: [10, 20, 15, 25, 30, 45] },
  //     { name: "AI", data: [10, 40, 45, 25, 20, 45] },
  //     { name: "AR", data: [15, 36, 88, 75, 20, 45] },
  //     { name: "Cobots", data: [2, 10, 15, 25, 30, 25] },
  //     { name: "Big Data", data: [9, 20, 25, 55, 60, 55] },
  //     { name: "CV & ML", data: [22, 25, 29, 25, 45, 55] },
  //   ],
  //   options: {
  //     chart: { type: "line", toolbar: { show: false } },
  //     title: { text: "Mastery progress over time - Future", align: "center" },
  //     xaxis: { categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"] },
  //   },
  // },
  {
    id: 11,
    isStatic: false, // Static graph
    // series: [
    //   {
    //     name: "Recruitment",
    //     data: [25, 50, 38, 40, 71, 86, 80],
    //   },
    // ],
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: true, barHeight: "80%", isFunnel: true },
      },
      title: { text: "Mastery Per Class - Future", align: "center" },
      // xaxis: {
      //   categories: [
      //     "Maths",
      //     "Physical science",
      //     "Life science",
      //     "Geography",
      //     "LO",
      //     "English FAL",
      //   ],
      // },
      xaxis: { categories: [] },
    },
    dataLabels: { enabled: false },
  },
  // Line Chart
  {
    id: 12,
    isStatic: false,
    // series: [
    //   { name: "IIOT", data: [10, 20, 15, 25, 30, 45] },
    //   { name: "AI", data: [10, 40, 45, 25, 20, 45] },
    //   { name: "AR", data: [15, 36, 88, 75, 20, 45] },
    //   { name: "Cobots", data: [2, 10, 15, 25, 30, 25] },
    //   { name: "Big Data", data: [9, 20, 25, 55, 60, 55] },
    //   { name: "CV & ML", data: [22, 25, 29, 25, 45, 55] },
    // ],
    series: [],
    options: {
      chart: { type: "line", toolbar: { show: false } },
      title: { text: "Mastery Progress Over Time - Future", align: "center" },
      // xaxis: { categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"] },
      xaxis: { categories: [] },
      colors:colors
    },
    dataLabels: { enabled: false },
  },
  {
    id: 13,
    isStatic: false, // Static graph
    // series: [
    //   {
    //     name: "Recruitment",
    //     data: [25, 50, 38, 40, 71, 86, 80],
    //   },
    // ],
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: true, barHeight: "80%", isFunnel: true },
      },
      title: { text: "Time Per Club Past 30 Days", align: "center" },
      xaxis: { categories: [] },
      // xaxis: {
      //   categories: [
      //     "Maths",
      //     "Physical science",
      //     "Life science",
      //     "Geography",
      //     "LO",
      //     "English FAL",
      //   ],
      // },
    },
    dataLabels: { enabled: false },
  },
  {
    id: 14,
    isStatic: false, // Static graph
    // series: [40, 30, 20, 10],
    series: [],
    options: {
      chart: { type: "donut", toolbar: { show: false } },
      // labels: ["Books", "Videos", "Webisties", "Lessons"],
      labels: [],
      title: { text: "Resource Type Preference", align: "center" },
      legend: {
        show: true,
        position: "bottom",
      },
    },
  },
  {
    id: 15,
    isStatic: false, // Static graph
    // series: [
    //   {
    //     name: "Funnel Series",
    //     data: [1380, 1100, 990, 880, 740, 548, 330, 200],
    //   },
    // ],
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: { show: false },
        dropShadow: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          isFunnel: true,
          borderRadius: 0,
          horizontal: true,
          barHeight: "80%",
          // distributed: false, // Ensure bars are not equally spaced
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function(val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        dropShadow: {
          enabled: true,
        },
      },
      title: {
        text: "Top Offline Resources (Last 30 Days)",
        align: "center",
      },
      xaxis: { categories: [] },
      // xaxis: {
      //   categories: [
      //     "Sourced",
      //     "Screened",
      //     "Assessed",
      //     "HR Interview",
      //     "Technical",
      //     "Verify",
      //     "Offered",
      //     "Hired",
      //   ],
      // },
      legend: {
        show: false,
      },
      // fill: {
      //   type: "gradient", // Add gradient to improve visualization
      //   gradient: {
      //     shade: "light",
      //     type: "horizontal",
      //     shadeIntensity: 0.25,
      //     inverseColors: false,
      //     opacityFrom: 0.85,
      //     opacityTo: 0.5,
      //     stops: [50, 100],
      //   },
      // },
    },
  },

  {
    id: 16,
    // isStatic: true, // Static graph
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: { show: false },
        dropShadow: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: "80%",
          isFunnel: true,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function(val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        dropShadow: {
          enabled: true,
        },
      },
      title: {
        text: "Top Online Resources Past 30 Days",
        align: "middle",
      },
      xaxis: {},
      legend: {
        show: false,
      },
    },
  },

  {
    id: 17,
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      title: { text: "Drops Awarded Past Month", align: "center" },
      xaxis: { categories: ["Week-1", "Week-2", "Week-3", "Week-4"] },
      plotOptions: {
        bar: {
          // isFunnel: true,
          borderRadius: 6,
          // horizontal: true,
          // barHeight: "80%",
          // distributed: false, // Ensure bars are not equally spaced
        },
      },
    },
  },
  {
    id: 18,
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      title: { text: "Drops Awarded/Redeemed This Year", align: "center" },
      dataLabels: { enabled: false },
    },
  },

  // Pie Chart
  {
    id: 19,
    series: [],
    options: {
      chart: {
        type: "treemap",
        height: 350,
      },
      title: {
        text: "Rewards Received (YTD)",
        align: "center",
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
          useFillColorAsStroke: false,
        },
      },
      colors: [
        "#3B93A5",
        "#F7B844",
        "#ADD8C7",
        "#EC3C65",
        "#CDD7B6",
        "#C1F666",
        "#D43F97",
        "#1E5D8C",
        "#421243",
        "#7F94B0",
        "#EF6537",
        "#C0ADDB",
      ],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          color: "#000000",
          fontWeight: "bold",
        },
        formatter: function(text, opts) {
          return `${text}: ${opts.value}`;
        },
      },
    },
  },
  {
    id: 20,
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      title: { text: "Support Tickets Raised (YTD)", align: "center" },
    },
  },
  {
    id: 21,
    series: [],
    // series: [
    //   {
    //     name: "Recruitment",
    //     data: [500, 400, 300, 200, 100],
    //   },
    // ],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: true, barHeight: "80%", isFunnel: true },
      },
      title: { text: "Nature of Tickets Raised", align: "center" },
      // xaxis: { categories: ["Sourced", "Screened", "Interviewed", "Hired"] },
    },
    dataLabels: { enabled: false },
  },
  {
    id: 22,
    series: [],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: true, barHeight: "80%", isFunnel: true },
      },
      title: {
        //   text: `Aunty A Rating (Total: ${totalRatings})`,
        align: "center",
      },
      xaxis: { min: 0, max: 100 },
      dataLabels: { enabled: false },
    },
  },
  {
    id: 23,
    // isStatic: false, // Static graph
    // series: [20, 20, 20, 20, 20],
    // series: [],
    options: {
      chart: { type: "donut", toolbar: { show: false } },
      // dataLabels: { enabled: true ,align:"below" },
      // labels: ["Happy", "Sad", "Romantic", "Meh", "Hehe"],
      labels: [],
      title: {
        // text: "Mood Meter Summary ",

        align: "center",
      },
      dataLabels: {
        enabled: true,
        // formatter: function (value, opts) {
        //   const label = opts.w.config.labels[opts.seriesIndex];
        //   const maxLength = 15;
        //   return label.length > maxLength ? label.substring(0, maxLength) + "..." : label;
        // },
      },
      // tooltip: {
      //   enabled: true,
      //   y: {
      //     formatter: function (value, opts) {
      //       return opts.w.config.labels[opts.seriesIndex];
      //     },
      //   },
      // },
      legend: {
        show: true,
        position: "bottom",
      },
    },
  },
];

export default graphConfigurations;
