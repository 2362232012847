import moment from "moment";
import {
  showTickCell,
  TextCell,
  DateCell,
} from "../../components/tables/helperCells";

const renderCell = (object, type, key) => {
  const value = object[key];
  switch (type) {
    case "DateCell":
      return DateCell(moment(value).format("DD/MM/YYYY | hh:mm:ss A"));
    default:
      return TextCell(value);
  }
};

function createColumns() {
  return [
    {
      title: "Date",
      key: "createdAt",
      className: "title-col text-wrap-wrap",
      width: "14%",
      render: (object) =>
        DateCell(moment(object?.createdAt).format("DD/MM/YYYY | hh:mm:ss A")),
    },

    {
      title: "Request",
      key: "issue",
      className: "title-col text-wrap-wrap",
      width: "38%",
      render: (object) => TextCell(object.issue),
    },
    {
      title: "Response",
      key: "message",
      className: "title-col text-wrap-wrap",
      width: "38%",
      render: (object) => TextCell(object?.message),
    },
    {
      title: "Rating",
      className: "title-col text-wrap-wrap",
      key: "rating",
      width: "10%",
      render: (object) => TextCell(object.rating ?? "NR"),
    },
  ];
}
export { createColumns };
