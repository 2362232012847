import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { createStructuredSelector } from "reselect"
import { Row, Col, Modal, Spin, Card, Icon } from "antd"
import Popover from "../../components/uielements/popover"
import userpic from "../../image/avatar.png"
import authAction from "../../redux/auth/actions"
import TopbarDropdownWrapper from "./topbarDropdown.style"
import { getProfile } from "../../redux/auth/selectors"
import Profile from "../Dashboard/profile"
import { capitalizeFirstLetter, serverBaseUrl } from "../../helpers/utility"
import UserWrapper from "../Users/user.style";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import LayoutContent from "../../components/utility/layoutContent";
import ModalAction from "../../components/Modal/ModalAction"
import moment from "moment"
import { ModalActions } from "../../components/tables/helperCells"
const { logout } = authAction

class TopbarUserDetails extends Component {
  constructor(props) {
    super(props)
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.hide = this.hide.bind(this)
    this.state = {
      visible: false,
      showEdit: false,
    }
  }

  hide() {
    this.setState({ visible: false })
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    let { user } = this.props
    const userImage = user && user.avatar ? `${user.avatar}` : userpic

    return (
      <div className="isoImgWrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Modal
          visible={this.state.visible}
          // width={window.innerWidth - 220}
          closable
          onCancel={() => { this.setState({ visible: false }) }}
          // closeIcon={
          //   <Icon title="Close" type="close-circle" theme="filled" onClick={() => { this.setState({ visible: false }) }} />
          // }
          title={"Edit Details"}
          width={1200}
          footer={false}
        >
          {
            !this.state.showEdit ?
              <LayoutContentWrapper>
                <LayoutContent style={{ padding: 0 }}>
                  <Spin spinning={false}>
                    {user && (
                      <UserWrapper>
                        <div className="user-panel" style={{ borderRight: "none" }}>
                          <div className="user-avatar">
                            <h3>{`${user.firstName} ${user.lastName ? user.lastName : ""
                              }`}</h3>
                            <img
                              src={user.avatar}
                              title="avatar"
                              style={{ marginTop: 5 }}
                            />
                          </div>
                        </div>
                        <div className="user-details-panel">
                          <Row>
                            <Col span={24}>
                              <ModalAction>
                                <ModalActions
                                  onEdit={() => { this.setState({ showEdit: true }) }}
                                // onDelete={this.removeUser}
                                // deleteMessage="Are you sure to delete this student?"
                                />
                              </ModalAction>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={20}>
                              <Row>
                                <Col span={12}>
                                  <label>First Name:</label>
                                  <span>{user.firstName}</span>
                                </Col>
                                <Col span={12}>
                                  <label>Last Name:</label>
                                  <span>{user.lastName}</span>
                                </Col>
                              </Row>
                              <Row span={24}>
                                {user?.houseName && (
                                  <Col span={12}>
                                    <label>House Name:</label>
                                    <span>{user.houseName}</span>
                                  </Col>
                                )}
                                <Col span={12}>
                                  <label>DOB:</label>
                                  <span>{moment(user.dob).format("DD-MM-YYYY")}</span>
                                </Col>
                                <Col span={12}>
                                  <label>Gender:</label>
                                  <span>{user.gender}</span>
                                </Col>
                                {user.email && (
                                  <Col span={12}>
                                    <label>Email:</label>
                                    <span className="no-transform">{user.email}</span>
                                  </Col>
                                )}
                                {user.phone && (
                                  <Col span={12}>
                                    <label>Phone:</label>
                                    <span>{user.phone}</span>
                                  </Col>
                                )}
                                {user.schools?.length && (
                                  <Col span={12}>
                                    <label>School:</label>
                                    <span>{user.schools[0]?.name}</span>
                                  </Col>
                                )}
                                {user.organizations?.length && (
                                  <Col span={12}>
                                    <label>Organization:</label>
                                    <span>{user.organizations[0]?.name}</span>
                                  </Col>
                                )}
                                {user.userType && (
                                  <Col span={12}>
                                    <label>Role:</label>
                                    <span>{user?.userType === "SUPER_ADMIN" ? "Super Admin" :
                                      user?.userType === "SCHOOL_ADMIN" ? "School Admin" :
                                        user?.userType === "ODIN_ADMIN" ? "Odin Admin" :
                                          user?.userType === "ORGANIZATION_ADMIN" ? "Organization Admin" :
                                            "Teacher"}</span>
                                  </Col>
                                )}
                                <Col span={12} />
                              </Row>
                              {this.props.userData?.classrooms?.length > 0 && <Row style={{ paddingRight: "10px", marginTop: "10px" }}>
                                {/* <Timeline>
                        {this.props.userData?.classrooms?.map((item,i)=> {
                           <Timeline.Item>{item?.name || "kittilla"}</Timeline.Item>
                        })}
                      </Timeline> */}

                              </Row>}
                            </Col>

                          </Row>
                        </div>
                      </UserWrapper>
                    )}
                  </Spin>
                </LayoutContent>
              </LayoutContentWrapper>
              :
              <Profile onUpdate={() => this.setState({ visible: false })} />
          }

        </Modal>
        <img onClick={() => {
          this.handleVisibleChange()
        }} alt="user" src={userImage} style={{
          width: "40px",
          height: "40px",
          objectFit: "cover",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        />
        <div style={{ padding: "0px 25px 0px 20px" }}>
          <div

          // to={`/dashboard/profile/${user.id}`}
          // className="user-profile-link"
          >
            <p style={{ marginBottom: "3px" }}>
              <strong>
                {capitalizeFirstLetter(user.firstName)}
              </strong>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  user: getProfile(),
})

export default connect(
  mapStateToProps,
  { logout }
)(TopbarUserDetails)
