import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableWrapper, { CustomizedTableWrapper } from "../antTable.style";
import OdinLogo from "../../../../image/odinLogo1.png";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowRender: props.expandedRowRender,
      onExpand: props.onExpand,
      onExpandedRowsChange: props.onExpandedRowsChange,
    };
    this.onChange = this.onChange.bind(this);
    this.sortKey = null;
    this.sortDir = null;
  }
  getSortAsc = (sortKey, data) => {
    this.sortKey = sortKey;
    this.sortDir = "ASC";
    return data.sort(this.sort);
  };

  getSortDesc = (sortKey, data) => {
    this.sortKey = sortKey;
    this.sortDir = "DESC";
    return data.sort(this.sort);
  };

  sort = (optionA, optionB) => {
    let valueA, valueB;
    if (this.sortKey === "role") {
      valueA = optionA?.role?.title?.toUpperCase();
      valueB = optionB?.role?.title?.toUpperCase();
    } else if (this.sortKey === "grade") {
      valueA = optionA?.grade?.name?.toUpperCase();
      valueB = optionB?.grade?.name?.toUpperCase();
    } else if (this.sortKey === "school") {
      valueA = optionA?.school?.name?.toUpperCase();
      valueB = optionB?.school?.name?.toUpperCase();
    } else if (this.sortKey === "subject") {
      valueA = optionA?.subject?.title?.toUpperCase();
      valueB = optionB?.subject?.title?.toUpperCase();
    } else {
      valueA = optionA[this.sortKey]
        ? optionA[this.sortKey]?.toUpperCase()
        : "";
      valueB = optionB[this.sortKey]
        ? optionB[this.sortKey]?.toUpperCase()
        : "";
    }

    let sortVal = 0;
    if (valueA > valueB) {
      sortVal = 1;
    }
    if (valueA < valueB) {
      sortVal = -1;
    }
    if (sortVal !== 0 && this.sortDir === "DESC") {
      return sortVal * -1;
    }
    return sortVal;
  };

  onChange(pagination, filters, sorter) {
    const { dataSource } = this.props;
    if (sorter && sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        this.getSortAsc(sorter.columnKey, dataSource);
      } else {
        this.getSortDesc(sorter.columnKey, dataSource);
      }
      // this.setState({ dataList: dataList.getAll() });
    }
  }

  onRowClick = (record, link) => {
    let url = null;
    switch (link) {
      case "courses":
        url = `${link}/${record.id}`;
        break;
      case "admin":
        url = `/dashboard/admin/details/${record.id}`;
        break;
      default:
        url = null;
    }
    if (url) {
      window.location.href = url;
    }
  };

  render() {
    const {
      dataSource,
      columns,
      pagination,
      rowSelection,
      expandedRowKeys,
      loading,
      link,
    } = this.props;
    let count = 0;
    return (
      <CustomizedTableWrapper className="isoCustomizedTableWrapper">
      <TableWrapper
        {...this.state}
        expandedRowKeys={expandedRowKeys ? expandedRowKeys : []}
        rowSelection={rowSelection ? rowSelection : null}
        pagination={pagination}
        rowKey={this.props.rowKey}
        onChange={this.onChange}
        columns={columns}
        dataSource={dataSource}
        className="isoSortingTable"
        // loading={loading}
        {...this.props}
        onRow={
          link
            ? (record, rowIndex) => {
                return {
                  onClick: (event) => this.onRowClick(record, link),
                };
              }
            : this.props.onRow
        }
        loading={{
          spinning: loading,
          indicator: <img src={OdinLogo} alt="Loading" style={{ width: "50px", height: "50px" }} className="rotate-odin-image1"/>,
          // tip: 'Loading data...',
        }}
      />
      </CustomizedTableWrapper>
    );
  }
}
