import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "home",
    component: asyncComponent(() => import("../HomeScreen")),
  },
  {
    path: "",
    component: asyncComponent(() => import("../DashboardNew")),
  },
  // {
  //   path: "dashboardNew",
  //   component: asyncComponent(() => import("../DashboardNew")),
  // },
  // {
  //   path: "resource-preview",
  //   component: asyncComponent(() =>
  //     import("../../containers/Courses/PDFPreviewPage")
  //   ),
  // },
  {
    path: "profile/:id",
    component: asyncComponent(() => import("../Dashboard/profile")),
  },
  {
    path: "role",
    component: asyncComponent(() => import("../Role")),
  },
  {
    path: "admin",
    component: asyncComponent(() => import("../Admin")),
  },
  {
    path: "admin/create",
    component: asyncComponent(() => import("../Admin/create")),
  },
  {
    path: "admin/details/:id",
    component: asyncComponent(() => import("../Admin/details")),
  },
  {
    path: "admin/edit/:id",
    component: asyncComponent(() => import("../Admin/create")),
  },
  {
    path: "users",
    component: asyncComponent(() => import("../Users")),
  },
  {
    path: "users/create",
    component: asyncComponent(() => import("../Users/create")),
  },
  {
    path: "user/details/:id",
    component: asyncComponent(() => import("../Users/details")),
  },
  {
    path: "user/edit/:id",
    component: asyncComponent(() => import("../Users/create")),
  },
  {
    path: "organizations",
    component: asyncComponent(() => import("../Organizations")),
  },
  {
    path: "schools",
    component: asyncComponent(() => import("../Schools")),
  },
  {
    path: "subjects",
    component: asyncComponent(() => import("../Subjects")),
  },
  {
    path: "courses",
    component: asyncComponent(() => import("../Courses")),
  },
  {
    path: "classroom",
    component: asyncComponent(() => import("../Classrooms")),
  },
  {
    path: "my-classroom",
    component: asyncComponent(() => import("../MyClassroom")),
  },
  {
    path: "lesson-builder",
    component: asyncComponent(() => import("../LessonBuilder")),
  },
  {
    path: "lessonbuilder",
    component: asyncComponent(() => import("../lessonBuilderNew")),
  },
  {
    path: "lesson-builder/create",
    component: asyncComponent(() => import("../LessonBuilder/create")),
  },
  {
    path: "lesson-builder/details/:id",
    component: asyncComponent(() => import("../LessonBuilder/details")),
  },
  {
    path: "lesson-builder/edit/:id",
    component: asyncComponent(() => import("../LessonBuilder/create")),
  },
  {
    path: "courses/:id",
    component: asyncComponent(() => import("../Courses/CourseDetails")),
  },
  {
    path: "grades",
    component: asyncComponent(() => import("../Grades")),
  },
  {
    path: "library",
    component: asyncComponent(() => import("../Books")),
  },
  {
    path: "library/create",
    component: asyncComponent(() => import("../Books/create")),
  },
  {
    path: "library/details/:id",
    component: asyncComponent(() => import("../Books/details")),
  },
  {
    path: "library/edit/:id",
    component: asyncComponent(() => import("../Books/create")),
  },
  {
    path: "webplatforms",
    component: asyncComponent(() => import("../Websites")),
  },
  {
    path: "mywebplatforms",
    component: asyncComponent(() => import("../Websites/bonusWebsites")),
  },
  {
    path: "mylibrary",
    component: asyncComponent(() => import("../Books/bonusContent")),
  },
  {
    path: "mylibrary/create",
    component: asyncComponent(() => import("../Books/createBonusContent")),
  },
  {
    path: "mylibrary/details/:id",
    component: asyncComponent(() => import("../Books/bonusContentDetails")),
  },
  {
    path: "mylibrary/edit/:id",
    component: asyncComponent(() => import("../Books/createBonusContent")),
  },
  {
    path: "devices",
    component: asyncComponent(() => import("../Devices")),
  },
  {
    path: "psm",
    component: asyncComponent(() => import("../PSM")),
  },
  {
    path: "psm/create",
    component: asyncComponent(() => import("../PSM/create")),
  },
  {
    path: "notification",
    component: asyncComponent(() => import("../Notifications")),
  },
  {
    path: "import",
    component: asyncComponent(() => import("../Import")),
  },
  {
    path: "chromeVersion",
    component: asyncComponent(() => import("../ChromeVersions")),
  },
  {
    path: "appVersion",
    component: asyncComponent(() => import("../AppVersions")),
  },
  {
    path: "settings",
    component: asyncComponent(() => import("../Import/custom")),
  },
  {
    path: "test",
    component: asyncComponent(() => import("../Test")),
  },
  {
    path: "test/:id",
    component: asyncComponent(() => import("../Test/TestReport")),
  },
  {
    path: "survey",
    component: asyncComponent(() => import("../Survey")),
  },
  {
    path: "survey/:id",
    component: asyncComponent(() => import("../Survey/SurveyReport")),
  },
  {
    path: "rewards",
    component: asyncComponent(() => import("../Reward")),
  },
  {
    path: "technicalsupport",
    component: asyncComponent(() => import("../Health")),
  },
  {
    path: "chat_groups",
    component: asyncComponent(() => import("../ChatGroups")),
  },
  {
    path: "chat_groups/:id",
    component: asyncComponent(() => import("../ChatGroups/GroupHistory")),
  },
  // {
  //   path: "zoom",
  //   component: asyncComponent(() => import("../ZomMeeting")),
  // },
  {
    path: "investor",
    component: asyncComponent(() => import("../Investor")),
  },
  {
    path: "bigbluemeeting",
    component: asyncComponent(() => import("../BigBlue")),
  },
  {
    path: "device_details",
    component: asyncComponent(() => import("../DeviceTransaction")),
  },
  {
    path: "feedback",
    component: asyncComponent(() => import("../StudentsTestimonials")),
  },
  {
    path: "investor_proposal",
    component: asyncComponent(() => import("../InvestorProposal")),
  },
  {
    path: "report",
    component: asyncComponent(() => import("../Reports")),
  },
  {
    path: "developer",
    component: asyncComponent(() => import("../Developer")),
  },
  {
    path: "analytics",
    component: asyncComponent(() => import("../Analytics")),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
