import "./export.style.css";
import { Row, Col } from "antd";
import React from "react";

const ReportHeader = ({ studentDetails, analyticsTitle }) => {
  const defaultLogo = JSON.parse(localStorage.getItem("DefaultLogos"))?.find(
    (item) => item.title === "admin"
  )?.logo;
  return (
    <div className="header">
      <Row>
        <Col span={6}>
          <img
            src={studentDetails?.avatar?.urlLowQuality || defaultLogo}
            alt="Avatar"
            crossOrigin="anonymous"
          />
        </Col>
        <Col span={18}>
          <h1 className="omang-title">Omang Individual Learning Report</h1>
          <Row>
            <Col span={12}>
              <p className="page-content">
                {studentDetails.firstName || "N/A"}{" "}
                {studentDetails.lastName || ""}
              </p>
              <p className="page-content" >{studentDetails.school?.name || "School not available"}</p>
              <p className="page-content">Accession NO: {studentDetails.accessionNumber}</p>
            </Col>
            <Col span={12}>
              <p className="page-content">Generated on: {new Date().toLocaleDateString()}</p>
              <p className="page-content" >
                User status:{" "}
                <span
                  style={{
                    color: studentDetails.isActive ? "green" : "red",
                  }}
                >
                  {studentDetails.isActive ? "Active" : "In Active"}
                </span>
              </p>
              <p>
                App version:{" "}
                <span
                  style={{
                    color:
                      studentDetails.device?.[0]?.appVersion?.version ===
                      studentDetails.device?.[0]?.expectedAppVersion?.version
                        ? "green"
                        : "red",
                  }}
                >
                  {studentDetails.device?.[0]?.appVersion?.version || "N/A"}
                </span>
              </p>
            </Col>
          </Row>
        </Col>
        <p className="analytics-title">{analyticsTitle}</p>
      </Row>
    </div>
  );
};

export default ReportHeader;
